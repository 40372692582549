.share-contract-modal {
    padding: 20px;
  }
  
  .share-contract-modal h2 {
    margin-bottom: 15px;
  }
  
  .share-contract-modal .form-group--share {
    margin-bottom: 15px;
  }
  
  .share-contract-modal .form-group--share label {
    display: block;
    margin-bottom: 5px;
  }
  
  .share-contract-modal .form-group--share input:focus {
    outline: 1px solid #64d2ff;
    transition: outline 0.3s ease;
  }
  .share-contract-modal .form-group--share input {
    width: 100%;
    padding: 8px;
    background-color: transparent;
    outline: 1px solid #444;
    transition: outline 0.3s ease;
    border: none;
    border-radius: 4px;
    font-size: 0.9rem;
    box-sizing: border-box;
  }
  
  .share-contract-modal .modal-actions {
    display: flex;
    justify-content: flex-end;
  }
  
  .share-contract-modal .modal-actions .btn-share {
    margin-right: 10px;
    border-radius: 30px;
    background-color: #1779b1;
    color: #fff;
    padding: 3px 16px;
    border: 1px solid #1779b1;
    cursor: pointer;
    transition: 0.3s ease border;
  }

  .share-contract-modal .modal-actions .btn-share:hover {
    border: 1px solid #fff;
    transition: 0.3s ease border;
  }
  
  .share-contract-modal .modal-actions .btn-cancel {
    background-color: #ccc;
    color: #000;
    border-radius: 30px;
    background-color: #555;
    color: #fff;
    padding: 3px 16px;
    border: 1px solid #555;
    cursor: pointer;
    transition: 0.3s all ease;
  }

  .share-contract-modal .modal-actions .btn-cancel:hover {
    border: 1px solid #fa0000;
    background-color: #fa0000;
    transition: 0.3s all ease;
  }
  