@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Global Styles */
html {
  transition: background-color 0.3s ease, color 0.3s ease;
}

body {
  @apply bg-background-light text-text-light;
  /* overflow: hidden; */ /* Removed to enable scrolling */
  -ms-overflow-style: none;
  scrollbar-width: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dark body {
  @apply bg-background-dark text-text-dark;
}

p,
span,
input,
textarea,
button,
label,
li {
  @apply font-sans font-normal leading-relaxed;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-heading font-normal;
}

@layer utilities {
  .custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #444 transparent;
  }

  /* WebKit-based browsers */
  .custom-scrollbar::-webkit-scrollbar {
    width: 4px;
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    background: #111;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #444;
    border-radius: 4px;
    border: 2px solid transparent;
  }
}

/* Hide Scrollbars */
html::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none;
}

/* Autofill Styling */
input:-webkit-autofill,
input:-moz-autofill,
input:-ms-autofill {
  transition: background-color 5000s ease-in-out 0s;
}

/* Button Styles using @apply */
@layer components {
  .btn {
    @apply font-light py-2 px-4 rounded transition duration-300 ease-in-out inline-flex items-center text-sm;
  }

  .btn-primary {
    @apply btn bg-primary-500 text-white;
  }

  .btn-primary:hover {
    @apply bg-primary-700;
  }

  .btn-secondary {
    @apply btn bg-secondary-500 text-white;
  }

  .btn-secondary:hover {
    @apply bg-secondary-700;
  }

  .btn-success {
    @apply btn bg-success-500 text-white;
  }

  .btn-success:hover {
    @apply bg-success-700;
  }

  .btn-warning {
    @apply btn bg-warning-500 text-white;
  }

  .btn-warning:hover {
    @apply bg-warning-700;
  }

  .btn-danger {
    @apply btn bg-danger-500 text-white;
  }

  .btn-danger:hover {
    @apply bg-danger-700;
  }

  .btn-disabled {
    @apply btn bg-disabled-500 text-gray-400 cursor-not-allowed;
  }
}

/* Alert Styles */
@layer components {
  .alert {
    @apply p-4 rounded-lg shadow transition-colors duration-300;
  }

  .alert-success {
    @apply bg-success-100 text-success-700 dark:bg-success-700 dark:text-success-100;
  }

  .alert-warning {
    @apply bg-warning-100 text-warning-700 dark:bg-warning-700 dark:text-warning-100;
  }

  .alert-danger {
    @apply bg-danger-100 text-danger-700 dark:bg-danger-700 dark:text-danger-100;
  }
}

/* Input Styles */
@layer components {
  .input {
    @apply w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white transition-all duration-300;
  }

  .input:focus {
    @apply border-primary-500;
  }
}

/* Container Example */
@layer components {
  .container-example {
    @apply bg-white dark:bg-gray-800 p-6 rounded-lg shadow-light-md dark:shadow-dark-md transition-colors transition-shadow duration-300;
  }
}

/* Badge Styles */
@layer components {
  .badge {
    @apply inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset;
  }
}


/* Chat group animation */

/* Initial state of the item before it enters */
.chat-group-enter {
  opacity: 0;
  transform: translateY(-20px);
}

/* State of the item during the entering transition */
.chat-group-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

/* Ensure no styles are overridden */
.chat-group-enter-done {
  opacity: 1;
  transform: translateY(0);
}

/* Exit transitions if needed */
.chat-group-exit {
  opacity: 1;
  transform: translateY(0);
}

.chat-group-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

/* Lawyer Card */
.card-hover:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

/* e.g. fadeIn.css */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(2px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.step-item {
  opacity: 0;            /* start hidden */
  animation: fadeInUp 0.25s ease forwards;
}

/* e.g. in index.css or a separate stylesheet */

.markdown-content {
  font-family: 'Inter', sans-serif;
  font-size: 0.9rem; /* Smaller for a cleaner look */
  line-height: 1.5; /* Tighter spacing for readability */
  color: inherit; /* Matches parent bubble’s color */
}

.markdown-content p {
  margin: 0.75em 0; /* Reduced spacing between paragraphs */
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3 {
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  font-weight: 600;
}

.markdown-content h1 {
  font-size: 1.2rem; /* Smaller headings */
}
.markdown-content h2 {
  font-size: 1.1rem;
}
.markdown-content h3 {
  font-size: 1rem;
}

.markdown-content ul,
.markdown-content ol {
  margin: 0.5em 0 0.5em 1.25em; /* Tighter list margins */
  padding: 0;
}

.markdown-content li {
  margin: 0.25em 0; /* Minimal spacing between list items */
}

.markdown-content code {
  background-color: #f2f2f2;
  color: #d63384;
  padding: 2px 4px;
  font-size: 0.85em; /* Smaller inline code */
  border-radius: 4px;
}

.markdown-content pre code {
  background-color: #f6f8fa;
  padding: 1em;
  display: block;
  overflow-x: auto;
  color: #e83e8c;
  border-radius: 4px;
}

.markdown-content blockquote {
  margin: 1em 0;
  padding: 0.5em 1em;
  border-left: 4px solid #ccc;
  background-color: #fafafa;
}

@keyframes fadeStep {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}
.fade-step {
  animation: fadeStep 1.5s infinite ease-in-out;
}


/* For chat items */
.chat-group-enter {
  opacity: 0;
  transform: translateY(-20px);
}
.chat-group-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
.chat-group-exit {
  opacity: 1;
  transform: translateY(0);
}
.chat-group-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

/* For headings */
.fade-in-up-enter {
  opacity: 0;
  transform: translateY(10px);
}
.fade-in-up-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
.fade-in-up-exit {
  opacity: 1;
  transform: translateY(0);
}
.fade-in-up-exit-active {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

@keyframes fadePulse {
  0% { opacity: 0.3; }
  50% { opacity: 0.5; }
  100% { opacity: 0.3; }
}
.custom-loader {
  animation: fadePulse 1.5s infinite;
}

@keyframes pulse {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.2); opacity: 0.7; }
  100% { transform: scale(1); opacity: 1; }
}
.animate-pulse {
  animation: pulse 1.5s infinite;
}

@keyframes pulseChat {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.0.5); opacity: 0.7; }
  100% { transform: scale(1); opacity: 1; }
}
.animate-pulse-chat {
  animation: pulse 1.5s infinite;
}

@keyframes fadePulse {
  0% { opacity: 0.3; }
  50% { opacity: 0.6; }
  100% { opacity: 0.3; }
}
.animate-fadePulse {
  animation: fadePulse 1.5s infinite ease-in-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}