.message-center {
  display: flex;
  height: 100%;
  ;
  background-color: #1e1f21;
  position: relative;
  padding: 0px 3px;
  margin: 0px 20px;
  border-radius: 10px;
}

.list-divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0; 
  color: #666; 
  font-size: 0.8rem;
  font-weight: 500; 
}

.list-divider::before,
.list-divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #aaa; 
  margin: 0 10px; 
}

.message-center--main {
  flex: 1;
}

.message-center--sidebar {
  width: 300px;
  background-color: #0D0D12;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  scrollbar-width: thin;
}

.chat-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  scrollbar-width: thin;
  scrollbar-color: #444 transparent;
  overflow-y: auto;
  height: calc(100%);
}

.chat-list-item {
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  /* border-bottom: 1px solid #34495e; */
  margin: 3px 0px;
  transition: background 0.3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.chat-item-role,
.chat-item-details {
  font-size: 0.8em;
  color: #bdc3c7;
}


.chat-list-item:hover,
.chat-list-item.selected {
  background-color: #34495e;
}

.chat-item-name {
  font-weight: 500;
  font-size: 0.9rem;
}

.chat-item-last-message {
  color: #bdc3c7;
  font-size: 0.8rem;
}

.toggle-button {
  display: flex;
  justify-content: space-around;
  padding: 0px;
  border-radius: 5px;
  background-color: #222;
}

.toggle-button button {
  flex: 1;
  padding: 10px;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.toggle-button button.active {
  background-color: #1779b1;
  transition: all 0.3s ease;
}

.chat-window {
  flex: 1;
  padding: 10px;
}

.chat-window-content {
  flex: 1;
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: #1e1f21;
}

/* .chat-window-header h3 {
  font-weight: 600;

} */

.chat-window-header {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #272B30;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.chat-window-subheaders {
  height: fit-content;
  /* background: red; */
  /* gap: -20px */
}


.chat-window-subheaders h3,
h5,
h6 {
  margin: -0px !important;
}

.chat-window-subheaders h3 {
  font-weight: 600 !important;
}

.chat-window-subheaders h5 {
  font-weight: 500 !important;
  font-size: 16px;
  color: #aaa;
}

.chat-window-subheaders h6 {
  font-weight: 400 !important;
  font-size: 14px;
  color: #64d2ff
}

.ellipsis-btn:hover {
  background-color: #444;
  transition: 0.3s ease background-color;
}
.ellipsis-btn {
  background-color: #201c1c;
  transition: 0.3s ease background-color;
  box-shadow: none;
  cursor: pointer;
  padding: 2px 6px;
  border-radius: 4px;
  border: none;
  color: #fff;
}

.chat-messages {
  flex: 1;
  padding: 20px;
  height: 100%;
  overflow-y: auto;
}

.chat-message {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

/* Style for messages sent by the user */
.chat-message.self {
  align-items: flex-end;
  /* Align the entire message block to the right */
}

/* Style for messages received from others */
.chat-message.other {
  align-items: flex-start;
  /* Align the entire message block to the left */
}

/* Style for the message content */
.lawyers--message-content {
  padding: 10px;
  color: #fff;
  border-radius: 5px;
  max-width: 60%;
  word-wrap: break-word;
}

/* Background colors for sent and received messages */
.chat-message.self .lawyers--message-content {
  background-color: #1779b1;
  /* Sent message background */
}

.chat-message.other .lawyers--message-content {
  background-color: #444;
  /* Received message background */
}

/* Timestamp styling */
.message-timestamp {
  font-size: 0.8em;
  color: #7f8c8d;
  margin-top: 5px;
}

/* Align sent message timestamps to the right */
.chat-message.self .message-timestamp {
  text-align: right;
}

.form-chat-input {
  padding: 10px;
  background-color: #1e1f21;
  border-top: 1px solid #666;
}

.chat-input {
  /* padding: 10px; */
  /* background-color: #1e1f21; */
  display: flex;
  /* border-top: 1px solid #666; */
}

.chat-input .file-upload-label:hover {
  border: 1px solid #64d2ff;
  transition: 0.3s ease border;
}

.chat-input .file-upload-label {
  margin: auto 5px;
  padding: 5px 10px;
  font-size: 16px;
  background-color: #0e1013;
  border: 1px solid #444;
  transition: 0.3s ease border;
  border-radius: 4px;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  color: #fff;
  border: none;
  outline: 1px solid #0D0D12;
  transition: 0.3s ease outline;
  border-radius: 5px;
  background-color: #0D0D12;
  margin-right: 10px;
}

.chat-input input:focus {
  outline: 1px solid #64d2ff;
  transition: 0.3s ease outline;
}

.attachments-container--msg {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 5px;
}

.attachment-item--msg {
  padding: 5px;
  border-radius: 4px;
  background-color: #0D0D12;

}
.chat-input button {
  padding: 5px 15px;
  background-color: #1779b1;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.form-chat-input .attachment-preview-item__marketplace i:hover {
  color: #fa0000;
  transition: 0.3s ease color;
}

.form-chat-input .attachment-preview-item__marketplace i {
  color: #555;
  cursor: pointer;
  opacity: 1;
  transition: 0.3s ease color;
  font-size: 20px;
}

.form-chat-input .attachment-preview-item__marketplace {
  padding: 3px 5px;
  width: fit-content;
  background: radial-gradient(#222, #000);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.8rem;
  gap: 5px;
  border-radius: 4px;
  color: #fff !important
}

.form-chat-input .attachments-preview__marketplace {
  margin: 2px 2px 10px 2px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-start;
  flex: 1;
  flex-wrap: wrap;
  border-radius: 5px;
  position: relative;
}

.empty-chat-window {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #7f8c8d;
  font-size: 1.2em;
}

.newChat-btn {
  border: none;
  outline-style: none;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #1779b1;
  color: #fff;
  font-weight: 600;
}

.icon-hover-effect-message-center {
  font-size: 15px;
  color: #64d2ff;
  cursor: pointer;
  margin-bottom: 10px;
  transition: opacity 0.3s ease;
  opacity: 1;
}

.icon-hover-effect-message-center:hover {
  opacity: 0.7;
}

.emp-center--sidebar {
  width: 320px;
  background-color: #0D0D12;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 10px 5px;
  border-radius: 10px;
}

.emp-center--sidebar-top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.list-cnt {
  overflow-y: hidden;
  height: calc(100%);
  scrollbar-width: none;
  width: 100%
}

.emp-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  height: calc(94%);
  scrollbar-width: none;
}

.emp-list-item:hover {
  background-color: #34495e;
  transition: 0.3s ease background-color;
}

.emp-list-item {
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  /* border-bottom: 1px solid #34495e; */
  margin: 3px 0px;
  transition: 0.3s ease background-color;
  background-color: #272B30;
  overflow: hidden;
  /* width: fit-content; */
}

.emp-item-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  gap: 10px
}

.emp-profile-image {
  height: 50px;
  width: 50px;
  border-radius: 10px;
  background-color: #222;
}
.system-msg-bubble-div{
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.msg-bubble {
  background-color: #33699f;
  color: rgba(255, 255, 255, 0.924);
  border-radius: 50%;
  width: 10px;
  height: 10px;
  padding: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.msg-bubble .unread-msg-count{
  /* font-weight: 400; */
  /* margin: auto; */

}

.unread_msg_color{
  color: #64d2ff;
}

.unread_msg_background{
  background-color: #00ff95;
  width: 15px;
  height: 15px;
}

.message-date {
  text-align: center;
  width: fit-content;
  margin: auto;
}

.employee-search-input {
  width: 95%;
  margin: 2px;
  padding: 5px;
  margin-bottom: 10px;
  border: none;
  outline: 1px solid #444;
  border-radius: 4px;
  font-size: 14px;
  background: #0e1013;
  transition: 0.3s ease outline;
  color: #fff;
}



.employee-search-input:focus {
  outline: 1px solid #64d2ff;
  transition: 0.3s ease outline;
}